import { defaultDirection } from '../constants/defaultValues';
import { BASE_URL, API_MEDIA_URL } from '../constants/services'
import moment from 'moment';
import parse from "html-react-parser";
import _ from "lodash";
import { ThemeColors } from './ThemeColors';
import { COMPANY, STAFF, DRIVER, RESELLER, MASTER } from "../security/roles";
import { AccountType } from "../constants/api";

export const safeParse = (text) => {
  if (!text)
    return;
  return parse(text.replace(/&quot;/g, '"'));
};

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};


export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem('direction')) {
    const localValue = localStorage.getItem('direction');
    if (localValue === 'rtl' || localValue === 'ltr') {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};

export const getSubdomain = () => {
  const full = window.location.host.split(":")[0]; // subdomain.domain.com
  const parts = full.split(".");
  return parts[0];
}

export const getSetup = async () => {
  const subdomain = getSubdomain();

  if (localStorage.getItem(`setup-${subdomain}`)) {
    const localSetup = localStorage.getItem(`setup-${subdomain}`);
    return localSetup;
  } else {
    try {
      const response = await fetch(BASE_URL + '/api/v1/setup?domain=' + subdomain);
      const setup = response.json();
      if (setup.domain) {
        localStorage.setItem(`setup-${subdomain}`, setup);
      }
      return setup;
    } catch (e) {
      // console.log(e)
    }
  }
};


export const getUserGuideUrl = () => {
  const subdomain = getSubdomain();
  return API_MEDIA_URL + "/manuals/" + subdomain + "/" + subdomain + ".pdf";
};


export const removeImgPrefix = (string) => {
  return string.split("base64,")[1];
};

export const getBase64 = (file, onOk, onError) => {
  if (!file) return;
  var img = null;
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    img = reader.result;
    if (img.startsWith("data:image")) {
      onOk(img);
    } else {
      onError('Invalid file format');
    }
  };
  reader.onerror = function (error) {
    onError('Error uploading file');
  };
}

export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  localStorage.setItem('direction', direction);
};

export const toLocalDate = (utcDate) => {
  const localDate = new Date(utcDate);
  const offset = localDate.getTimezoneOffset();
  return moment(localDate.getTime() + (offset * 60 * 1000));
}

export const timeout = async (ms) => {
  return new Promise(res => setTimeout(res, ms));
}

export const twoDigits = (number) => {
  let numberStr = number.toString();
  if (numberStr.length < 2) {
    number = '0' + number;
  }
  return number;
}

export const getLocalSetup = () => {
  const subdomain = getSubdomain();
  const setup = localStorage.getItem(`setup-${subdomain}`);
  return JSON.parse(setup);
}


export const isExpired = (expirationYear, expirationMonth) => {
  var exp = moment(expirationYear + "/" + expirationMonth, "YYYY/M");
  var today = moment();
  return today >= exp.add(1, 'months');
}

export const hasValidCreditCard = (user) => {
  var result = false;
  if (user && user.paymentMethods && user.paymentMethods.length > 0) {
    let i = 0;
    while (i < user.paymentMethods.length && !result) {
      let pm = user.paymentMethods[i];
      console.log("PM", pm);
      if (!isExpired(pm.expirationYear, pm.expirationMonth)) {
        result = true;
      }
      i++;
    }
    return result;
  }
}

export const isValidURL = (string) => {
  var pattern = new RegExp("^(https?|ftp)://[^s/$.?#].[^s]*$", "i");
  return !!pattern.test(string);
};

export const toCurrencyFormat = (value) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })
  return formatter.format(value);
}

export const formatNumber = (value) => {
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0
  })
  return formatter.format(value);
}

export const formatNumber2Decimals = (value) => {
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2
  })
  return formatter.format(value);
}

// https://stackoverflow.com/a/18650828/14300761
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

// returns the id text label used in the IntlMessages
export const enumChargingSessionStateToLabel = (state) => {
  switch (state) {
    case 'INITIAL':
      return 'transaction.pages.initial-state';
    case 'AUTHORIZED':
      return 'transaction.pages.authorized-state';
    case 'CHARGING':
      return 'transaction.pages.charging-state';
    case 'NOT_CHARGING':
      return 'transaction.pages.not-charging-state';
    case 'CHARGING_FINISHED':
      return 'transaction.pages.charging-finished-state';
    case 'COMPLETED':
      return 'transaction.pages.completed-state';
    case 'ERROR':
      return 'transaction.pages.error-state';
    default:
      return 'general.no-state-found';
  }
}

export const enumChargerStateToLabel = (state, connectivity) => {

  if (connectivity == "OFFLINE") {
    return state == 'OUT_OF_SERVICE' ? 'chargers.pages.out-of-service-state' : 'chargers.pages.offline-state';
  }

  switch (state) {
    case 'AVAILABLE':
      return 'chargers.pages.available-state';
    case 'IN_USE':
      return 'chargers.pages.in-use-state';
    case 'OUT_OF_SERVICE':
      return 'chargers.pages.out-of-service-state';
    case 'PLUGGED_IN_IDLE':
      return 'chargers.pages.plugged-in-idle-state';
    default:
      return 'general.no-state-found';
  }
}

export const enumChargerModeToLabel = (mode) => {

  switch (mode) {
    case 'STANDARD':
      return 'charger.mode-standard';
    case 'PROTECTED':
      return 'charger.mode-protected';
    case 'FREE':
      return 'charger.mode-free';
    case 'PROTECTED_PAYG':
      return 'charger.mode-protected-payg';
    case 'STANDARD_PAYG':
      return 'charger.mode-standard-payg';
    default:
      return 'charger.no-mode';
  }
}

export const enumPaymentStateToLabel = (state) => {
  switch (state) {
    case 'CREATED':
      return 'payment.pages.created-state';
    case 'PROCESSING':
      return 'payment.pages.processing-state';
    case 'REQUIRES_ACTION':
      return 'payment.pages.requires-action-state';
    case 'CANCELED':
      return 'payment.pages.canceled-state';
    case 'REFUNDED':
      return 'payment.pages.refunded-state';
    case 'SUCCEEDED':
      return 'payment.pages.succeeded-state';
    case 'FAILED':
      return 'payment.pages.failed-state';
    case 'REQUIRES_CAPTURE':
      return 'payment.pages.requires-capture-state';
    case 'REQUIRES_REFUND':
        return 'payment.pages.requires-refund-state';
    default:
      return 'general.no-state-found';
  }
}

export const enumPaymentStateToClass = (state) => {
  switch (state) {
    case 'CREATED':
      return 'secondary';
    case 'PROCESSING':
      return 'secondary';
    case 'REQUIRES_ACTION':
      return 'secondary';
    case 'CANCELED':
      return 'secondary';
    case 'REFUNDED':
      return 'danger';
    case 'SUCCEEDED':
      return 'primary';
    case 'FAILED':
      return 'danger';
    case 'REQUIRES_CAPTURE':
      return 'secondary';
    default:
      return 'secondary';
  }
}

export const enumPropertyTypeToLabel = (propertyType) => {
  switch (propertyType) {
    case 'MULTIFAMILY_COMMUNITY':
      return 'properties.pages.type-mf-commnuity';
    case 'RESORT':
      return 'properties.pages.type-resort';
    case 'HOTEL':
      return 'properties.pages.type-hotel';
    case 'COMPLEX':
      return 'properties.pages.type-complex';
    case 'CONDO':
      return 'properties.pages.type-condo';
    case 'APARTMENT':
      return 'properties.pages.type-apartment';
    case 'ROOM':
      return 'properties.pages.type-room';
    default:
      return 'properties.pages.type-single-home';
  }
}

export const isCommunity = (propertyType) => {
  switch (propertyType) {
    case 'MULTIFAMILY_COMMUNITY':
    case 'RESORT':
    case 'HOTEL':
    case 'COMPLEX':
      return true;
    default:
      return false;
  }
}

export const enumPropertyStateToLabel = (state) => {
  switch (state) {
    case 'OCCUPIED':
      return 'properties.pages.occupied-state';
    case 'VACANT':
      return 'properties.pages.vacant-state';
    case 'READY':
      return 'properties.pages.ready-state';
    default:
      return 'general.no-state-found';
  }
}

export const enumPropertyTaskTypeStateToLabel = (state) => {
  switch (state) {
    case 'INSTALLATION':
      return 'property-tasks.pages.installation-state';
    case 'REPAIR':
      return 'property-tasks.pages.repair-state';
    case 'MAINTENANCE':
      return 'property-tasks.pages.maintenance-state';
    default:
      return 'general.no-state-found';
  }
}

export const enumPropertyTaskStateToLabel = (state) => {
  switch (state) {
    case 'NEW':
      return 'property-tasks.pages.new-state';
    case 'ASSIGNED':
      return 'property-tasks.pages.assigned-state';
    case 'STARTED':
      return 'property-tasks.pages.started-state';
    case 'FINISHED':
      return 'property-tasks.pages.finished-state';
    case 'CLOSED':
      return 'property-tasks.pages.closed-state';
    case 'REOPENED':
      return 'property-tasks.pages.reopened-state';
    case 'CANCELLED':
      return 'property-tasks.pages.cancelled-state';
    case 'ACCEPTED':
      return 'property-tasks.pages.accepted-state';
    case 'HOLD':
      return 'property-tasks.pages.hold-state';
    case 'REJECTED':
      return 'property-tasks.pages.rejected-state';
    default:
      return 'general.no-state-found';
  }
}

export const enumTransactionBillingTypeToLabel = (type) => {
  switch (type) {
    case 'BY_DISTANCE':
      return 'transaction.pages.bill-type-distance';
    case 'BY_ENERGY':
      return 'transaction.pages.bill-type-energy';
    case 'BY_MONEY':
      return 'transaction.pages.bill-type-money';
    case 'BY_TIME':
      return 'transaction.pages.bill-type-time';
    case 'NONE':
      return 'transaction.pages.bill-type-none';
    default:
      return 'general.no-state-found';
  }
}



export const enumChargingSessionSourceToLabel = (type) => {
  switch (type) {
    case 'PAYG_AUTH':
    case 'DRIVER':
      return 'transaction.pages.source-payg';
    case 'PAYG_NON_AUTH':
      return 'transaction.pages.source-payg-na';
    case 'RESERVATION':
      return 'transaction.pages.source-reservation';
    case 'FREE':
      return 'transaction.pages.source-free';
    case 'GRANTED_FREE':
    case 'GRANTED':
      return 'transaction.pages.source-granted-free';
    case 'GRANTED_PAYG':
      return 'transaction.pages.source-granted-payg';
    case 'RESERVATION_PACKAGE':
      return 'transaction.pages.source-reservation-package';
    case 'PROPERTY_PACKAGE':
      return 'transaction.pages.source-property-package';
    case 'OWNER':
      return 'transaction.pages.source-owner';
    default:
      return 'general.no-state-found';
  }
}

export const enumChargingSessionSourceToSubLabel = (type) => {
  switch (type) {
    case 'PAYG_AUTH':
      return 'transaction.pages.source-payg.sub';
    case 'PAYG_NON_AUTH':
      return 'transaction.pages.source-payg-na.sub';
    case 'GRANTED_PAYG':
      return 'transaction.pages.source-granted-payg.sub';
    case 'RESERVATION_PACKAGE':
      return 'transaction.pages.source-reservation-package.sub';
    case 'PROPERTY_PACKAGE':
      return 'transaction.pages.source-property-package.sub';
    default:
      return null;
  }
}

export const enumChargingSessionSourceToDescLabel = (type) => {
  switch (type) {
    case 'PAYG_AUTH':
    case 'DRIVER':
      return 'transactions.pay-as-you-go-authenticated';
    case 'PAYG_NON_AUTH':
      return 'transactions.pay-as-you-go-non-authenticated';
    case 'RESERVATION':
      return 'transactions.reservation';
    case 'FREE':
      return 'transactions.free-mode';
    case 'GRANTED_FREE':
    case 'GRANTED':
      return 'transactions.granted-free';
    case 'GRANTED_PAYG':
      return 'transactions.granted-pay-as-you-go';
    case 'RESERVATION_PACKAGE':
      return 'transactions.reservation-package';
    case 'PROPERTY_PACKAGE':
      return 'transactions.property-package';
    case 'OWNER':
      return 'transactions.owner-reservation';
    default:
      return 'general.no-state-found';
  }
}



export const chargingSessionSourceColor = (type) => {
  switch (type) {
    case 'FREE':
    case 'GRANTED_FREE':
      return 'primary';
    default:
      return 'secondary';
  }
}



export const enumAclToLabel = (type) => {

  if (!type)
    return 'users.acl-default';
  
  switch (type) {
    case 'OPERATOR':
    case 'VIEWER':
      return 'users.acl-operator';
    case 'INSTALLER':
      return 'users.acl-installer';
    case 'ADMIN':
      return 'users.acl-admin';
    default:
      return 'users.acl-default';
  }
}

export const enumAccountTypeToLabel = (type) => {
  switch (type) {
    case COMPANY:
      return 'users.account-type-company';
    case STAFF:
      return 'users.account-type-staff';
    case MASTER:
      return 'users.account-type-master';
    case RESELLER:
      return 'users.account-type-reseller';
    case DRIVER:
      return 'users.account-type-driver';
    case "PORTFOLIO":
        return 'users.account-type-portfolio';
    default:
      return type;
  }
}

export const accountTypeToColor = (type) => {
  switch (type) {
    case MASTER:
      return 'danger';
    case COMPANY:
      return 'primary';
    case STAFF:
    case AccountType.PORTFOLIO:
      return 'warning';
    default:
      return 'secondary';
  }
}


export const userDisplayName = (user) => {
  if ((user?.account?.accountType == COMPANY || user?.type == COMPANY) && (user?.account?.companyName || user?.companyName)) {
    return user?.account?.companyName || user?.companyName;
  }
  
  return user.fullName ? user.fullName : (user.firstName + " " + user.lastName);  
}

export const enumReservationEvToLabel = (reservation, type = "default") => {

  let mode = reservation.evChargingStatus;
  if (reservation.type == 'O') {
    mode = 'OWNER';
  }

  if (type == "short") {
    switch (mode) {
      case 'OWNER':
        return 'reservations.ev-paid.short.owner';
      case 'FREE':
        return 'reservations.ev-paid.short.free';
      case 'PAID':
        return 'reservations.ev-paid.short.paid';
      case 'RESERVED':
        return 'reservations.ev-paid.short.reserved';
      case 'ENABLED':
        return 'reservations.ev-paid.short.enabled';
      case 'INVITE_SENT':
        return 'reservations.ev-paid.short.sent';
      case 'INVITE_OPENED':
        return 'reservations.ev-paid.short.opened';
      case 'INVITE_ACCEPTED':
        return 'reservations.ev-paid.short.accepted';
      case 'NONE':
      default:
        return 'reservations.ev-paid.short.none';
    }
  }

  switch (mode) {
    case 'DRIVER':
      return 'reservations.ev-paid.driver';
    case 'FREE':
      return 'reservations.ev-paid.free';
    case 'PAID':
      return 'reservations.ev-paid.paid';
    case 'RESERVED':
      return 'reservations.ev-paid.reserved';
    case 'ENABLED':
      return 'reservations.ev-paid.enabled';
    case 'INVITE_SENT':
      return 'reservations.ev-paid.sent';
    case 'INVITE_OPENED':
      return 'reservations.ev-paid.opened';
    case 'INVITE_ACCEPTED':
      return 'reservations.ev-paid.accepted';
    case 'NONE':
    default:
      return 'reservations.ev-paid.none';
  }
}

export const enumReservationEvToInfoLabel = (reservation) => {
  switch (reservation.evChargingStatus) {
    case 'PAID':
      return 'reservations.ev-paid.paid-info';
    case 'RESERVED':
      return 'reservations.ev-paid.reserved-info';
    case 'ENABLED':
      return 'reservations.ev-paid.enabled-info';
    case 'INVITE_SENT':
      return 'reservations.ev-paid.sent-info';
    case 'INVITE_OPENED':
      return 'reservations.ev-paid.opened-info';
    case 'INVITE_ACCEPTED':
      return 'reservations.ev-paid.accepted-info';
    case 'NONE':
    default:
      return 'reservations.ev-paid.none-info';
  }
}

export const enumReservationEvToDotClass = (reservation) => {

  if (reservation.type == 'O') {
    return 'green-dot';
  }

  if (reservation.packagesCount > 0) {
    return 'green-dot';
  }

  switch (reservation.evChargingStatus) {
    case 'PAID':
    case 'ENABLED':
    case 'INVITE_ACCEPTED':
      return 'green-dot';
    case 'NONE':
      return 'red-dot';
    default:
      return 'yellow-dot';
  }
}

export const enumPackageTypeToLabel = (type) => {
  switch (type) {
    case 'FULL_STAY':
      return 'packages.type.full-stay';
    case 'SPOT_DAY':
      return 'packages.type.spot-day';
    case 'EXTENSION_STAY':
      return 'packages.type.extension-stay';
  }
}




export const chargingSessionStateColor = (state) => {
  switch (state) {
    case 'COMPLETED':
    case 'CHARGING':
      return 'primary';
    case 'AUTHORIZED':
    case 'INITIAL':
      return 'secondary';
    case 'CHARGING_FINISHED':
      return 'danger';
    case 'NOT_CHARGING':
    case 'ERROR':
      return 'warning';
    default:
      return '';
  }
}

export const chargingSpotReservationStateColor = (state) => {
  switch (state) {
    case 'CREATED':
      return 'secondary';
    case 'CONFIRMED':
      return 'primary';
    case 'ERROR':
      return 'danger';
    case 'CANCELED':
    case 'NO_SHOW':
      return 'warning';
    default:
      return 'secondary';
  }
}

export const chargerStateColor = (state, connectivity) => {
  if (connectivity == "OFFLINE")
    return 'danger';

  switch (state) {
    case 'AVAILABLE':
      return 'primary';
    case 'IN_USE':
      return 'secondary';
    case 'OUT_OF_SERVICE':
      return 'danger';
    case 'PLUGGED_IN_IDLE':
      return 'warning';
    default:
      return '';
  }
}

export const packagesStateColor = (state) => {
  switch (state) {
    case 'CONFIRMED':
      return 'primary';
    case 'CREATED':
      return 'secondary';
    case 'CANCELED':
      return 'danger';
    default:
      return '';
  }
}

export const userReservationTypeColor = (type) => {
  switch (type) {
    case 'O':
      return 'warning';
    default:
      return 'primary';
  }
}


// since filters could have
export const filterIsEmpty = (filter) => {
  if (_.isEmpty(filter)) {
    return true;
  }
  let containsElements = false;
  const keys = Object.keys(filter);
  for (let i = 0; i < keys.length; i++) {
    const att = keys[i];
    const element = filter[att];
    if (!(typeof element === 'undefined' || element == null || element == '')) {
      containsElements = true;
      break;
    }
  }
  return !containsElements;

}

export const getSessionMoment = (session) => {

  const date = session.startTime ? session.startTime : session.createdOn;
  return moment(date * 1000);

}

export const getSessionEnergy = (session) => {
  return session.state == "COMPLETED" ? session.kwhConsumption : session.kwhProgress;
}

export const getSessionDuration = (session) => {

  if (session.startTime && session.endTime) {
    return moment.duration(session.endTime - session.startTime, 'seconds').humanize({ ss: 1, s: 60, m: 60, h: 48 });
  }

  if (session.startTime) {
    return moment.duration(moment().diff(session.startTime * 1000)).humanize({ ss: 1, s: 60, m: 60, h: 48 });
  }

  return "0";

}

export const enumPolicyToText = (policy) => {
  if (policy.discount) {
    return policy.discount + "% OFF";
  }

  if (policy.type)
    return policy.type;

  return "FREE";
}

export const downloadFile = async (blob, name) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  // 3. Append to html page
  document.body.appendChild(link);
  // 4. Force download
  link.click();
  // 5. Clean up and remove the link
  link.parentNode.removeChild(link);
}


export const enumOemIdToText = (oem) => {
  switch (oem.code) {
    case 'GSTY':
      return 'Guesty JWT token';
    default:
      return 'Id in ' + oem.name;
  }
}
export const getLabelFromMeter = (meter, withUnit = true, messages, id) => {
  let label;
  let unit = getUnitFromMeter(meter);
  switch (meter) {
    case "energy":
      label = "charger.energy";
      break;
    case "temperature":
      label = "charger.temperature";
      break;
    case "power":
      label = "charger.power";
      break;
    case "voltage":
      label = "charger.voltage";
      break;
    case "current":
      label = "charger.current";
      break;
    case "powerFactor":
      label = "charger.power-factor";
      break;
    case "frequency":
      label = "charger.frequency";
      break;
  }
  return withUnit ? messages[label] + " (" + unit + ")" : messages[label];
};

export const getUnitFromMeter = (meter) => {
  switch (meter) {
    case "energy":
      return "kWh";
    case "temperature":
      return "\u00B0F";
    case "power":
      return "kW";
    case "voltage":
      return "V";
    case "current":
      return "A";
    case "powerFactor":
      return "%";
    case "frequency":
      return "Hz";
  }
  return "";
};


export const getColorFromMeter = (meter) => {
  switch (meter) {
    case "energy":
    default:
      return ThemeColors().themeColor1;
    case "temperature":
      return ThemeColors().themeColor5;
    case "power":
      return ThemeColors().themeColor6;
    case "voltage":
      return ThemeColors().themeColor1;
    case "current":
      return ThemeColors().themeColor5;
    case "powerFactor":
      return ThemeColors().themeColor6;
    case "frequency":
      return ThemeColors().themeColor1;
  }
};

export const getBkgColorFromMeter = (meter) => {
  switch (meter) {
    case "energy":
    default:
      return ThemeColors().themeColor1_10;
    case "temperature":
      return ThemeColors().themeColor5_10;
    case "power":
      return ThemeColors().themeColor6_10;
    case "voltage":
      return ThemeColors().themeColor1_10;
    case "current":
      return ThemeColors().themeColor5_10;
    case "powerFactor":
      return ThemeColors().themeColor6_10;
    case "frequency":
      return ThemeColors().themeColor1_10;
  }
};

export const meters = [
  "energy",
  "temperature",
  "power",
  "voltage",
  "current",
  "powerFactor",
  "frequency",
];

export const getDescFromMeter = (selectedMeter, messages) => {
  switch (selectedMeter) {
    case "energy":
    default:
      return messages["charger.meter.energy"];
    case "temperature":
      return messages["charger.meter.temperature"];
    case "power":
      return messages["charger.meter.power"];
    case "voltage":
      return messages["charger.meter.voltage"];
    case "current":
      return messages["charger.meter.current"];
    case "powerFactor":
      return messages["charger.meter.power-factor"];
    case "frequency":
      return messages["charger.meter.frequency"];
  }
};


export const getChartForChargingSession = (meterValues, startTime, meterType, startKwh = 0, messages, id) => {
  let labels = [];
  let data = [];
  let prevKwh = startKwh;

  if (meterValues && meterValues.length > 0) {
    meterValues.forEach((mv, index) => {
      if (mv[meterType] != null && mv["power"] != null) {
        //labels.push(moment(mv.timestamp * 1000).format("h:mm a"));
        //labels.push(Math.round((mv.timestamp-startTime)/60)+" min");
        labels.push(moment.duration(mv.timestamp - startTime, 'seconds').humanize({ ss: 1, s: 60, m: 60, h: 48 }));
        if (meterType == "energy") {
          data.push((mv[meterType] - prevKwh).toLocaleString("en-US", { maximumFractionDigits: 2 }));
          prevKwh = mv[meterType];
        } else {
          data.push(mv[meterType]);
        }
      }
    });
  }

  return {
    labels: labels,
    datasets: [
      {
        label: getLabelFromMeter(meterType, true, messages, id),
        data: data,
        borderColor: getColorFromMeter(meterType),
        borderWidth: 2,
        pointBackgroundColor: ThemeColors().foregroundColor,
        pointBorderColor: getColorFromMeter(meterType),
        pointHoverBackgroundColor: getColorFromMeter(meterType),
        pointHoverBorderColor: ThemeColors().foregroundColor,
        pointRadius: 0,
        //pointBorderWidth: 2,
        pointHoverRadius: 4,
        fill: true,
        min: 0,
        backgroundColor: getBkgColorFromMeter(meterType),
      },
    ],
  };
};

export const getChargerDeviceId = (qrString) => {
  if (!qrString) return;
  const words = qrString.split('/chargers/');
  if (words.length > 1) {
    return words[1];
  }
}

export const getChargerHashId = (qrString) => {
  if (!qrString) return;
  const words = qrString.split('/sc/'); //TODO: to be defined
  if (words.length > 1) {
    return words[1];
  }
}


export const isEvReservation = (reservation) => {

  if (reservation.evChargingStatus == "PAID" || reservation.evChargingStatus == "ENABLED")
    return true;

  if (reservation.type == 'O')
    return true;

  if (reservation.packagesCount > 0)
    return true;

}

export const transfersStateColor = (state) => {
  switch (state) {
    case 'CREATED':
    case 'PENDING':
      return 'secondary';
    case 'FAILED':
      return 'danger';
    case 'COMPLETED':
      return 'primary';
    case 'REVERSED':
    case 'CANCELED':
      return 'warning';
    default:
      return 'secondary';
  }
}


export const secondsToHourMin = (sec) => {
  let hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  let type = (hours < 12 || hours == 24) ? 'AM' : 'PM'
  hours = hours == 0 ? 12 : (hours <= 12 ? hours : hours - 12);
  return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes} ${type}`;
} 


export const printChargerVersion = (version) => {

  if (!version) {
    return "";
  }

  if (version.includes(",")) {
    let v = version.split(",")[0];
    if (v.includes("OCPP_")) {
      return v.split("OCPP_")[1];
    }
    if (v.includes("OCPP-")) {
      return v.split("OCPP-")[1];
    }
  }
  return version;
}

export const enumLsgStateToLabel = (state) => {
  switch (state) {
    case 'AVAILABLE':
      return 'chargers.pages.available-state';
    case 'IN_USE':
      return 'chargers.pages.in-use-state';
    case 'OUT_OF_SERVICE':
      return 'chargers.pages.out-of-service-state';
    case 'PLUGGED_IN_IDLE':
      return 'chargers.pages.plugged-in-idle-state';
    default:
      return 'general.no-state-found';
  }
}

export const loadSharingGroupStatusStr = (chargers, messages) => {
  const statuses = []
  const inUse = chargers.filter(c => c.state === "IN_USE")
  const plugged = chargers.filter(c => c.state === "PLUGGED_IN_IDLE")
  const available = chargers.filter(c => c.state === "AVAILABLE")
  const outOfService = chargers.filter(c => c.state === "OUT_OF_SERVICE")
  if (inUse.length) {
    statuses.push(`${inUse.length} ${messages[enumLsgStateToLabel('IN_USE')]}`)
  }
  if (plugged.length) {
    statuses.push(`${plugged.length} ${messages[enumLsgStateToLabel('PLUGGED_IN_IDLE')]}`)
  }
  if (available.length) {
    statuses.push(`${available.length} ${messages[enumLsgStateToLabel('AVAILABLE')]}`)
  }
  if (outOfService.length) {
    statuses.push(`${outOfService.length} ${messages[enumLsgStateToLabel('OUT_OF_SERVICE')]}`)
  }
  return statuses.join(', ')
}

export const enumLoadSharingGroupSharingAlgorithmToLabel = (lsg) => {
  switch (lsg) {
    case 'EQUAL_SHARING':
      return 'lsg.pages.equal-sharing'
      case 'FIRST_IN_FIRST_CHARGE':
      return 'lsg.pages.first-in-first-charge'
    default:
      break;
  }
}

export const loadSharingAlgorithm = [
  { label: 'Equal Sharing', value: 'EQUAL_SHARING', key: 0 },
  //{ label: 'First in first charge', value: 'FIRST_IN_FIRST_CHARGE', key: 1 },
];
