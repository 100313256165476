



export const CHARGING_SESSION_STATES = ['INITIAL', 'AUTHORIZED','CHARGING', 'CHARGING_FINISHED','COMPLETED', 'ERROR'];
export const CHARGING_SESSION_SOURCES = ['PAYG_NON_AUTH', 'PAYG_AUTH', 'GRANTED_PAYG', 'GRANTED_FREE', 'FREE', 'RESERVATION', 'OWNER', 'RESERVATION_PACKAGE', 'PROPERTY_PACKAGE' ];

export const BUSINESS_TYPES = ['VACATION_RENTAL','MULTI_FAMILY','SINGLE_FAMILY','WORKPLACE','RENT_BY_OWNER','COMMERCIAL','FLEET_MANAGEMENT'];

const ADMIN = 'ADMIN';
const VIEWER = 'VIEWER';
const NONE = 'NONE';
export const AccessLevel = {
    ADMIN,
    VIEWER,
    NONE
  };
export const ACCESS_LEVELS = [ADMIN, VIEWER, NONE];

const DRIVER = 'DRIVER';
const COMPANY = 'COMPANY';
const PORTFOLIO = 'PORTFOLIO';
const MASTER = 'MASTER';
export const AccountType = {
    DRIVER,
    COMPANY,
    PORTFOLIO,
    MASTER
  };
  export const ACCOUNT_TYPES = [DRIVER,COMPANY,PORTFOLIO,MASTER];

