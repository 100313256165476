import React, { useState, useEffect } from "react";
import { SEARCH_FILTERS, SESSION_TOKEN, USER_DATA, SESSION_ACCOUNT, TOURS_COMPLETED } from "../constants/localStorageKeys";
import _ from "lodash";
import { AccessLevel, AccountType } from "../constants/api";

const AuthContext = React.createContext();

const emptyUser = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  photoUrl: null
}


const AuthProvider = ( props ) => {
  const [state, setState] = useState({
    token: localStorage.getItem(SESSION_TOKEN),
    user: localStorage.getItem(USER_DATA) != null ? JSON.parse(localStorage.getItem(USER_DATA)) : emptyUser,
    currentAccount: localStorage.getItem(SESSION_ACCOUNT) != null ? JSON.parse(localStorage.getItem(SESSION_ACCOUNT)) : {},

    //will be deprecated, are now in currentAccount
    //acl: localStorage.getItem("acl"),
    //account: localStorage.getItem("account_id"),
    //accountType: localStorage.getItem("account_type"),
    //businessType: localStorage.getItem("business_type")

  });



  //save user info in local storage
  useEffect(() => {
    localStorage.setItem(USER_DATA, JSON.stringify(state.user));
  }, [state.user]);

  useEffect(() => {
    console.log("current account", state.currentAccount);
    if (_.isEmpty(state.currentAccount)) { //for users logged in with api v1, we'll force a login
      console.log("will logout");
      logout();
    } else {
      localStorage.setItem(SESSION_ACCOUNT, JSON.stringify(state.currentAccount));
    }
  }, [state.currentAccount]);


  const isAdmin = state.currentAccount?.acl === AccessLevel.ADMIN || state.currentAccount?.accountType == AccountType.MASTER;
  const isMaster = state.currentAccount?.acl === AccessLevel.ADMIN && state.currentAccount?.accountType === AccountType.MASTER;
  const isMulti = state.currentAccount?.accountType === AccountType.MASTER || state.currentAccount?.accountType === AccountType.PORTFOLIO;
  
  const acl = state.currentAccount?.acl;
  const account = state.currentAccount?.id;
  const accountType = state.currentAccount?.accountType;
  const businessType = state.currentAccount?.businessType;

  const authFetchPure = (input, init?) => {
    init = init || {};
    init.headers = {
      ...init.headers,
      "X-BH-OK2-AUTH-TOKEN": state.token,
    };
    if (init.body) {
      init.headers = {
        ...init.headers,
        "Content-Type": "application/json",
      }
    }
    return fetch(input, init);
  }

  const authFetch = async (input: RequestInfo, init?: RequestInit, deleteHeader): Promise<Response> => {
    init = init || {};
    init.headers = {
      ...init.headers,
      "X-BH-OK2-AUTH-TOKEN": state.token,
    };
    if (init.body) {
      init.headers = {
        ...init.headers,
        "Content-Type": "application/json",
      }
    }

    if (deleteHeader) {
      delete init.headers['Content-Type'];
    }
    return fetch(input, init).then((response) => {
      if (response.ok) {
        return response;
      } else {
        if (response.status == 401) {
          logout();
          window.location.reload();
          return;
        }

        let error = new Error('Something went wrong');
        throw { ...error, response };
      }
    }).catch((e) => {
      if (!e.response) {
        let error = new Error('Something went wrong');
        let response = { status: "Timeout" };
        throw { ...error, response };
      } else {
        throw e;
      }
    })
  };

  const residentAuthFetch = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    init = init || {};
    init.headers = {
      ...init.headers,
      "X-BH-IPASS-RESIDENT-AUTH-TOKEN": state.token,
    };
    if (init.body) {
      init.headers = {
        ...init.headers,
        "Content-Type": "application/json",
      }
    }
    return fetch(input, init).then((response) => {
      if (response.ok) {
        return response;
      } else {
        if (response.status == 401) {
          logout();
        }
        let error = new Error('Something went wrong');
        throw { ...error, response };
      }
    });
  };


  const login = async (data) => {
    var user = { ...data.user }
    var currentAccount = user?.accounts[0];

    setState({
      token: data.token,
      currentAccount: currentAccount,
      user: user,

      //will be deprecated, are now in currentAccount
      acl: currentAccount.acl,
      account: currentAccount.id,
      accountType: currentAccount.accountType,
      businessType: currentAccount.businessType
    });

    localStorage.setItem(SESSION_TOKEN, data.token); //for auth
    localStorage.setItem(USER_DATA, JSON.stringify(data.user));
    localStorage.setItem(SESSION_ACCOUNT, JSON.stringify(currentAccount));

    //will be deprecated, are now in currentAccount
    //localStorage.setItem("account_id", currentAccount.id); //for requests
    //localStorage.setItem("account_type", currentAccount.accountType); //for keep track of account type when changing the account (PM sub users)
    //localStorage.setItem("business_type", currentAccount.businessType);

    console.log("User logged in", localStorage);
  };

  // when user selects the dropdown option at the top
  const setUserAccount = (account) => {
    setState({
      ...state,
      currentAccount: account,
      //will be deprecated, are now in currentAccount
      //account: account.id,
      //accountType: account.accountType,
      //businessType: account.businessType,
      //acl: account.acl,
    });

    localStorage.setItem(SESSION_ACCOUNT, JSON.stringify(account));

    //will be deprecated, are now in currentAccount
    //localStorage.setItem("acl", account.acl); //for keep track of account type when changing the account (PM sub users)
    //localStorage.setItem("account_id", account.id); //for requests
    //localStorage.setItem("account_type", account.accountType); //for keep track of account type when changing the account (PM sub users)
    //localStorage.setItem("business_type", account.businessType);

  }

  const setUserProfile = (profile) => {
    setState({
      ...state,
      user: {
        ...state.user,
        ...profile
      }
    });
  }


  const logout = () => {
    localStorage.removeItem(SESSION_ACCOUNT);
    localStorage.removeItem(SESSION_TOKEN);
    localStorage.removeItem(USER_DATA);

    //app data
    localStorage.removeItem(TOURS_COMPLETED);
    localStorage.removeItem(SEARCH_FILTERS);

    //will be deprecated, are now in currentAccount
    localStorage.removeItem("acl");
    localStorage.removeItem("account_id");
    localStorage.removeItem("account_type");
    localStorage.removeItem("business_type");

    console.log("User logged out");
  };

  const register = (data) => {
    setState({ ...state, ...data });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        authFetch,
        authFetchPure,
        residentAuthFetch,
        setUserProfile,
        setUserAccount,
        isAdmin,
        isMaster,
        isMulti,
        acl, //will be deprecated
        businessType, //will be deprecated
        accountType, //will be deprecated
        account  //will be deprecated
      }} {...props}>
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };