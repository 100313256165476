export const SESSION_TOKEN = 'token';
export const SESSION_ACCOUNT = 'account';
export const USER_DATA = 'user';

export const SEARCH_FILTERS = 'filters';
export const TOURS_COMPLETED = 'tours_completed';
export const CURRENT_LANGUAGE = 'current_language';

export const SYNC_PROPERTIES_TASK = 'syncPropertiesTaskId';
export const SYNC_RESERVATIONS_TASK = 'syncReservationsTaskId';


