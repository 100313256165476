import {
  MENU_SET_CLASSNAMES,
  MENU_CONTAINER_ADD_CLASSNAME,
  MENU_CLICK_MOBILE_MENU,
  MENU_CHANGE_DEFAULT_CLASSES,
  MENU_CHANGE_HAS_SUB_ITEM_STATUS,
  LOAD_MENU,
  MENU_LOADED,
  MENU_ERROR
} from "../actions";

import {
  defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
} from "../../constants/defaultValues";

const INIT_STATE = {
  loading: true,
  items: null,
  error: false,
  containerClassnames: defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
  menuClickCount: 0,
  selectedMenuHasSubItems: defaultMenuType === "menu-default", // if you use menu-sub-hidden as default menu type, set value of this variable to false
};

export default (state = INIT_STATE, action) => {
  let items = state.items;
  switch (action.type) {
    
    case MENU_CHANGE_HAS_SUB_ITEM_STATUS:
      return { ...state, selectedMenuHasSubItems: action.payload };

    case MENU_SET_CLASSNAMES:
      return {
        ...state,
        containerClassnames: action.payload.containerClassnames,
        menuClickCount: action.payload.menuClickCount,
      };
    
    {/*case MENU_ENABLE_DASHBOARD:
      
      if(items && items[0].id != 'dashboard') 
        items = [...items];
      return {
        ...state,
        items
      };

      case MENU_ENABLE_SMART_HOME:
    
        if(items[1].id != 'secondmenu')
          items = [...items.slice(0, 1), ...items.slice(1)];
        return {
          ...state,
          items
        };
      */}

    case MENU_CLICK_MOBILE_MENU:
      return {
        ...state,
        containerClassnames: action.payload.containerClassnames,
        menuClickCount: action.payload.menuClickCount,
      };

    case MENU_CONTAINER_ADD_CLASSNAME:
      return { ...state, containerClassnames: action.payload };

    case MENU_CHANGE_DEFAULT_CLASSES:
      return { ...state, containerClassnames: action.payload };
    case LOAD_MENU:
      return { ...state, loading: true }
    case MENU_LOADED:
      return { ...state, loading: false, error: false, items: action.menu }
      // use the below to testing menu instead of calling the backend
      // return { ...state, loading: false, error: false, items: menuItems }
    case MENU_ERROR:
      return { ...state, loading: false, error: true }
    default:
      return { ...state };
  }
};
