import { ADMIN_CHANGE_DASHBOARD_TAB } from '../actions';

const INIT_STATE = {
  activeTab: "transactions"
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADMIN_CHANGE_DASHBOARD_TAB:
      return { ...state, activeTab: action.payload };

    default:
      return { ...state };
  }
};
