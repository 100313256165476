import React, { useState } from "react";
import { SEARCH_FILTERS, TOURS_COMPLETED } from "../constants/localStorageKeys";

/**
 * App Context
 * 
 * In this context we manage application information that becomes available after
 * the Authentication of the user is successful.
 */
const AppContext = React.createContext();

const AppDataProvider = (props) => {

  const [hideFooter, setHideFooter] = useState(false);
  const [doTour, setDoTour] = useState(false);

  //Help tours
  const saveNewTourCompleted = (tour) => {
    // if has tour parse to list and push new tour
    if (localStorage.getItem(TOURS_COMPLETED) != null) {
      let tours = JSON.parse(localStorage.getItem(TOURS_COMPLETED));
      // prevent if user clicks on help tour button to add it again
      if (tours.includes(tour)) return;
      tours.push(tour);
      localStorage.setItem(TOURS_COMPLETED, JSON.stringify(tours));
    } else {
      // if doesn't have any tour, create new array with one tour
      let tours = [tour];
      localStorage.setItem(TOURS_COMPLETED, JSON.stringify(tours));
    }
  }

  const getToursCompleted = () => {
    return localStorage.getItem(TOURS_COMPLETED) != null ? JSON.parse(localStorage.getItem(TOURS_COMPLETED)) : [];
  }

  //Filters
  const saveSearchFilter = (page, filter) => {
    if (localStorage.getItem(SEARCH_FILTERS) != null) {
      let filters = JSON.parse(localStorage.getItem(SEARCH_FILTERS));
      filters[page] = filter;
      localStorage.setItem(SEARCH_FILTERS, JSON.stringify(filters));
    } else {
      let filters = {};
      filters[page] = filter;
      localStorage.setItem(SEARCH_FILTERS, JSON.stringify(filters));
    }
  }

  const getSearchFilter = (page, defaultFilter = {}) => {
    let filters = localStorage.getItem(SEARCH_FILTERS);
    return filters ? (JSON.parse(filters)[page] || defaultFilter) : defaultFilter;
  }

  return (
    <AppContext.Provider
      value={{ setDoTour, doTour, getToursCompleted, saveNewTourCompleted,  
      saveSearchFilter, getSearchFilter, setHideFooter, hideFooter }} {...props}>
    </AppContext.Provider>
  );
}

export { AppDataProvider, AppContext }